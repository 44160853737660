import React, { useState, useEffect } from 'react';
import VacancyService from '../../../../domain/services/vacancy/VacancyService';
import JobApplicationForm from '../../common/jobApplicationForm/JobApplicationForm';
import Search from '../../common/search/Search';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './JobList.css';

export default function JobList() {
    const [vacancies, setVacancies] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);

    useEffect(() => {
        const fetchVacancies = async () => {
            try {
                const data = await VacancyService.getAllVacancies();
                setVacancies(data);
            } catch (error) {
                console.error('Failed to fetch vacancies:', error);
            }
        };

        fetchVacancies();
    }, []);

    const handleSearchResults = (results) => {
        setVacancies(results);
    };

    const handleApply = (job) => {
        setSelectedJob(job);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedJob(null);
    };

    return (
        <div>
            <Search onSearchResults={handleSearchResults} />
            <section className="vitrine" id="jobs">
                {vacancies.length > 0 ? (
                    vacancies.map((job, index) => (
                        <div className="vaga" key={index}>
                            <h2>{job.title}</h2>
                            <p><strong>Empresa:</strong> {job.employment_name}</p>
                            <div className="descricao">
                                <p><strong>Descrição:</strong> {job.description}</p>
                                <p className="local"><strong>Local:</strong> {job.city_name}</p>
                            </div>
                            <button onClick={() => handleApply(job)}>Candidatar-se</button>
                        </div>
                    ))
                ) : (
                    <p>No vacancies available</p>
                )}
            </section>
          {/* Modal de candidatura */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Formulário de Candidatura"
            ariaHideApp={false}
            className="job-element"
            // style={modalStyle} // Aplicar o estilo dinâmico
          >
            {selectedJob && (
              <div>
                <button onClick={closeModal} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faTimes} style={{ fontSize: '20px', color: '#000' }} />
                </button>
                <JobApplicationForm vacancy={selectedJob} />
              </div>
            )}
          </Modal>
        </div>
    );
}
