import React from 'react'
import { Link } from 'react-scroll';
import './Footer.css'


export default function Footer(){
    return(
        <footer>
        <div className="footer-wrapper" id='contact'>
            <h3>LM CONSULTORIA RH</h3>
            <p>A LM Consultoria é uma empresa especializada em soluções de Recursos Humanos</p>
            <div className="social-media">
                <a href="https://www.facebook.com/LMConsu/"><i className="fab fa-facebook-f"></i></a>
                <a href=""><i className="fab fa-twitter"></i></a>
                <a href="https://www.linkedin.com/company/91718519/admin/dashboard/"><i className="fab fa-linkedin-in"></i></a>
                <a href="https://wa.me/+5516974046985"><i className="fab fa-whatsapp"></i></a>
            </div>
        </div>
        <div className="footer-wrapper">
            <h4>Explore</h4>
            <Link to="companies" smooth={true} duration={500}>Principais empresas</Link>
            <Link to="" smooth={true} duration={500}>Termos de Serviços</Link>           
            <Link to="blog" smooth={true} duration={500}>Carreiras</Link>
        </div>
        <div className="footer-wrapper">
            <h4>About</h4>
            <a href="">FAQ</a>
            <a href="">Inspire-se</a>
            <a href="#blog">Blog</a>           
        </div>      
        <div className="footer-wrapper">
            <h4>Suporte</h4>
            <a href="">Cel: (16)974046985</a>
            <a href="">Email: lmconsultoriastz@gmail.com</a>
            <a href="">Cidade: Sertãozinho -SP</a>           
        </div>
    </footer>
    )
}