import VacancyRepositoryImpl from '../../repositories/vacancy/VacancyRepositoryImpl'

class VacancyService {
  constructor(vacancyRepository) {
    this.vacancyRepository = vacancyRepository;
  }

  async getAllVacancies() {
    return await this.vacancyRepository.getAllVacancies();
  }

  async getVacancyById(id){
    return await this.vacancyRepository.getVacancyById(id);
  }

  async searchVacancies(query) {
    return await this.vacancyRepository.searchVacancies(query);
  }

  // async getVacancyById(id){
  //   return await this.vacancyRepository.getVacancyById(id);
  // }

  // async createVacancy(formDataWithFile) {
  //   return await this.vacancyRepository.createVacancy(formDataWithFile);
  // }

  // async updateVacancy(id, formDataWithFile) {
  //   return await this.vacancyRepository.updateVacancy(id, formDataWithFile);
  // }

  // async deleteVacancy(id){
  //   return await this.vacancyRepository.deleteVacancy(id);
  // }


  



  // Adicione outros métodos conforme necessário
}

// Exportar uma instância do serviço com o repositório implementado
const vacancyRepository = new VacancyRepositoryImpl();
const vacancyService = new VacancyService(vacancyRepository);
export default vacancyService;