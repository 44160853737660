import React from 'react'
import Nav from '../components/common/nav/Nav'
import Header from '../components/common/header/Header'
import Footer from '../components/common/footer/Footer'
import JobList from '../components/home/joblist/JobList'
import FeaturedCompanies from '../components/home/featuredCompanies/FeaturedCompanies'
import Testimonial from '../components/home/testimonial/Testimonial'
import Blog from '../components/home/blog/Blog'
import JoinDetail from '../components/home/joinDetail/JoinDetail'

export default function HomePage(){
  
    return(
    <>
      <Nav />
      <Header />    
      {/* <Filter />  */}
      <JobList/>
      <JoinDetail />  
      <FeaturedCompanies /> 
      <Testimonial/>  
      <Blog />
      <Footer/>
    </>
    )
     
}