import React from 'react'
import './JoinDetail.css'


export default function JoinDetail(){
    return(
        <section>
        <div className="join">
            <div className="join-detail">
                <h1 className="section-title">
                VAMOS COMEÇAR SEU NOVO TRABALHO CONOSCO</h1>
                <p>A LM Consultoria é uma empresa especializada em soluções de Recursos Humanos, 
                focada em conectar talentos às melhores oportunidades do mercado.
                Com uma abordagem personalizada, oferecemos serviços de recrutamento e seleção, desenvolvimento
                profissional e consultoria estratégica para empresas de diversos setores. 
                Nosso compromisso é identificar as necessidades de cada cliente e candidato, proporcionando
                uma experiência eficiente e humanizada. Trabalhamos para impulsionar o crescimento organizacional e ajudar profissionais a encontrar o emprego ideal.
                Confiança, agilidade e excelência são os pilares que sustentam a LM Consultoria.
                </p>
            </div>
            {/* <button className="join-button">Cadastre-se agora</button> */}
        </div>
    </section>  
    )
}