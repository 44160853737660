import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import './Testimonial.css'

export default function Testimonial() {
  return (
    <section className="testimoni" id="testimoni">  
      <Swiper
        spaceBetween={20}
        slidesPerView={3}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}  
        className="mySwiper"
        breakpoints={{
          // Para telas menores que 700px, exibe 1 slide por vez
          0: {
            slidesPerView: 1,
            spaceBetween: 10, 
          },
          // Para telas maiores que 700px, exibe 3 slides por vez
          700: {
            slidesPerView: 3,
            spaceBetween: 20, 
          },
        }}
      >
         <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Publicação de Vagas</h3>
          <div className="testimoni-pic">
            <img src="/img/bannercentral.jpg" alt="John Smith" />
            <p>
            Podemos ajudar as
            empresas a redigir descrições de
            cargos claras e atraentes para as vagas
            disponíveis. Isso inclui identificaros
            requisitos específicos do cargo,
            responsabilidades, qualificações
            necessárias e benefícios oferecidos.
            Além disso, eles podem ajudar a
            determinar os canais de publicação
            mais eficazes para alcançar o
            público-alvo adequado.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Triagem de Currículos</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>
            Podemos
            receber e revisar os currículos
            recebidos para uma vaga, utilizando
            critérios pré-definidos pela empresa
            para selecionar os candidatos mais
            adequados para avançar no processo
            de seleção. Isso pode incluir a análise
            de experiência relevante, habilidades
            técnicas e educacionais, entre outros.
            </p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Entrevistas e Avaliação de Candidatos</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>
            Podemos conduzir entrevistas preliminares com os
            candidatos selecionados, avaliando suas
            habilidades, experiência e adequação cultural à
            empresa.
            Eles podem realizar testes psicométricos, avaliações
            de habilidades específicas ou outras formas de
            avaliação para garantir a qualidade dos candidatos
            apresentados.
            </p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Experiência Profissional</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Adicione sua experiência profissional. Liste as empresas para as quais você trabalhou, as posições que você ocupou e as datas de início e término.</p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Cargos</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Descreva suas responsabilidades e realizações para cada cargo. Destaque suas habilidades e conhecimentos.</p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Idiomas</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Inclua idiomas que você fala, habilidades de informática e outros conhecimentos relevantes para o trabalho.</p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Extracurricular</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Adicione qualquer experiência voluntária ou extracurricular. Isso pode incluir atividades comunitárias, membro de associações profissionais, etc.</p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Fotos</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Adicione uma foto se você estiver se candidatando a um trabalho no setor de atendimento ao cliente, vendas, eventos, entre outros.</p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Simplicidade</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Faça um currículo simples, com uma estrutura clara e fácil de ler. Use uma fonte legível e manter espaçamento entre as seções.</p>           
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <i className="fas fa-quote-left"></i>
          <h3>Alinhamento</h3>
          <div className="testimoni-pic">
            <img src="/img/banner2.jpg" alt="John Smith" />
            <p>Certifique-se de que o currículo está alinhado com o trabalho para o qual você está se candidatando.</p>           
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}
