import React, { useState } from 'react';
import VacancyService from '../../../../domain/services/vacancy/VacancyService';
import './Search.css';

export default function Search({ onSearchResults }) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = async () => {
        if (searchTerm.length > 2) {
            try {
                const data = await VacancyService.searchVacancies(searchTerm);
                console.log('Resultado do filtros search', data);
                if (onSearchResults) {
                    onSearchResults(data); // Atualiza as vagas no componente pai (JobList)
                }
            } catch (error) {
                console.error('Failed to search vacancies:', error);
            }
        } else {
            if (onSearchResults) {
                onSearchResults([]); // Limpa a lista se o termo for muito curto
            }
        }
    };

    return (
        <div className="search-wrapper">
            <div className="search-box">
                <div className="search-card">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Título do trabalho ou palavras-chave"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Apenas atualiza o estado
                    />
                    <button className="search-button" onClick={handleSearch}>
                        Buscar
                    </button>
                </div>
            </div>
        </div>
    );
}
