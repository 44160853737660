import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import JobApplicationForm from '../components/common/jobApplicationForm/JobApplicationForm'


const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Rota para a página inicial */}
        <Route path="/" element={<HomePage />} >
        </Route> 
        <Route path="/apply/:id" element={<JobApplicationForm />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;